import React, { ReactNode } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Helmet from "react-helmet";
import { IProject } from "~/types/Project";
import { FluidObject } from "gatsby-image";

interface ProjectSEOProps {
  project: IProject;
  location: Location;
  imagelocation?: string;
  children?: ReactNode;
}

const siteQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    }
  }
`;

export default function ProjectSEO({
  project,
  location,
  imagelocation,
  children,
}: ProjectSEOProps) {
  const results = useStaticQuery(siteQuery);
  const site = results.allSite.edges[0].node.siteMetadata;
  const imgLocation = site.siteUrl + (project.heroImgSrc.childImageSharp.fluid as FluidObject).src;
  const metaTags = [
    { charset: "utf-8" },
    {
      "http-equiv": "X-UA-Compatible",
      content: "IE=edge",
    },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1",
    },
    { itemprop: "name", content: site.title },
    // TODO: add excerpt as description
    // { itemprop: 'description', content: description || site.description },
    { itemprop: "image", content: imgLocation },
    // TODO: add excerpt as description
    // { name: 'description', content: description || site.description },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: site.name },
    { name: "twitter:title", content: site.title },
    {
      name: "twitter:image",
      content: imgLocation,
    },
    { property: "og:type", content: "website" },
    { property: "og:title", content: site.title },
    { property: "og:url", content: site.siteUrl + location.pathname },
    { property: "og:image", content: imgLocation },
    // TODO: add excerpt as description
    // { name: 'description', content: description || site.description },
    { property: "og:site_name", content: site.title },
  ];

  return (
    <Helmet htmlAttributes={{ lang: "en" }} title={project.name} meta={metaTags}>
      {children}
    </Helmet>
  );
}
