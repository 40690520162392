import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
// @ts-ignore
import { MDXProvider } from "@mdx-js/react";
import { Container, MDXBody } from "./style";
import { ImageZoom } from "~/components/Image";
import OverflownHeadline from "~/components/OverflownHeadline";
import AppearingParagraph from "~/components/AppearingParagraph";

interface BodyProps {
  content: string;
}

const components = {
  p: ({ children, ...props }: any) => (
    <AppearingParagraph {...props}>{children}</AppearingParagraph>
  ),
  img: ImageZoom,
  h1: ({ children, ...props }: any) => (
    <OverflownHeadline as="h1" showInView {...props}>
      {children}
    </OverflownHeadline>
  ),
  h2: ({ children, ...props }: any) => (
    <OverflownHeadline as="h2" showInView {...props}>
      {children}
    </OverflownHeadline>
  ),
  h3: ({ children, ...props }: any) => (
    <OverflownHeadline as="h3" showInView {...props}>
      {children}
    </OverflownHeadline>
  ),
};

const Body = React.forwardRef<HTMLElement, BodyProps>(({ content }, ref) => (
  <Container ref={ref}>
    <MDXProvider components={components}>
      <MDXBody>
        <MDXRenderer>{content}</MDXRenderer>
      </MDXBody>
    </MDXProvider>
  </Container>
));

export default Body;
