import React from "react";
import styled from "styled-components";

export const OverlapContainer = styled.div<{ isOverlapping: boolean }>`
  user-select: ${p => (p.isOverlapping ? "none" : "initial")};
  pointer-events: ${p => (p.isOverlapping ? "none" : "initial")};
  opacity: ${p => (p.isOverlapping ? 0 : 1)};
  transition: ${p => (p.isOverlapping ? "opacity 0.25s" : "opacity 0.25s")};
`;

export const AsideContainer = styled.aside`
  display: flex;
  ${p => p.theme.dimensions.mixins.centeredBody.static}

  ${p => p.theme.media.desktop_medium`
    display: none;
  `}
`;

export const Align = React.memo(styled.div<{
  show: boolean;
  shouldFixAside: boolean;
  imageOffset: number;
}>`
  position: ${p => (p.shouldFixAside ? "fixed" : "absolute")};
  display: flex;
  transform: translateY(0px);
  top: ${p => (p.shouldFixAside ? 0 : p.imageOffset)}px;
  align-items: ${p => (p.shouldFixAside ? "center" : "flex-start")};
  height: 100vh;
  z-index: 3;

  opacity: ${p => (p.show ? 1 : 0)};
  visibility: ${p => (p.show ? "visible" : "hidden")};
  transition: ${p =>
    p.show
      ? "opacity 0.4s linear, visibility 0.4s linear"
      : "opacity 0.2s linear, visibility 0.4s linear"};
`);
