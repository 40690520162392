import styled, { css } from "styled-components";
import Chip from "~/components/Chip";

export const Container = styled.div`
  ${p => p.theme.mixins.roundedMobileEdgesMixin}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    position: relative;
    z-index: 10;
    margin: 0 auto 90px;
    padding-left: 68px;
    padding-top: 68px;
    max-width: 749px;
    ${theme.media.desktop`
      padding-left: 53px;
      max-width: calc(507px + 53px);
    `}
    ${theme.media.tablet`
      padding-left: 0;
      max-width: 480px;
    `}
    ${theme.media.phablet`
      margin: 0 auto;
      padding: 170px 40px 180px 40px;
    `}
    @media screen and (max-height: 700px) {
      margin: 100px auto;
    }
  `}
`;

export const HeroHeading = styled.h1`
  ${({ theme }) => css`
    font-size: 48px;
    margin-bottom: 25px;
    font-weight: bold;
    line-height: 1.32;
    ${theme.media.tablet`
      margin-bottom: 20px;
      font-size: 36px;
    `}
    ${theme.media.phablet`
      font-size: 32px;
    `}
  `}
`;

export const HeroSubtitle = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: inline-block;
    font-size: 18px;
    color: ${theme.palette.onBackground.shade1};

    & > span {
      margin-left: 0.3em;
    }
  `}
`;

export const HeroImage = styled.div`
  ${({ theme }) => css`
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 944px;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0 30px 60px -10px rgba(0, 0, 0, 0.2), 0 18px 36px -18px rgba(0, 0, 0, 0.22);
    ${theme.media.tablet`
      max-width: 100%;
    `}
    ${theme.media.phablet`
      margin: 0 auto;
      width: calc(100vw - 40px);
      height: 220px;
      & > div {
        height: 220px;
      }
    `}
  `}
`;

export const ChipsContainer = styled.div`
  margin: 0.75em -0.33em 0 -0.33em;
`;

export const StyledChip = styled(Chip)`
  margin: 0.25em 0.33em;
`;
