// https://github.com/narative/gatsby-theme-novela/blob/master/%40narative/gatsby-theme-novela/src/sections/article/Article.Aside.tsx
import React, { useState, useRef, useEffect, ReactElement } from "react";
import { AsideContainer, Align } from "./style";
import throttle from "lodash/throttle";
import clamp from "~/utils/clamp";

import HandleOverlap from "./HandleOverlap";

interface AsideProps {
  contentHeight: number;
  children: ReactElement | ReactElement[];
}

/**
 * Aside: the wonderful fixed positioned elements that are to the left
 * and the right of the written content on our articles. For example, the
 * progress bar and dark controls are within an Aside. The main responsibility
 * of this component is to show or hide its children if it's at the top or bottom
 * of the page!
 *
 * The left and right Asides!
 *
 * left Aside ----> |  content  | <--- right Aside
 *                  |  content  |
 *                  |  content  |
 *                  |  content  |
 *
 */
export default function Aside({ contentHeight, children }: AsideProps) {
  const progressRef = useRef<HTMLDivElement>(null);

  const [progress, setProgress] = useState<number>(0);
  const [imageOffset, setImageOffset] = useState<number>(0);
  const [shouldFixAside, setShouldFixAside] = useState<boolean>(false);

  const show = imageOffset && progress < 100;
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { show }),
  );

  useEffect(() => {
    const imageRect = document.getElementById("ArticleImage__Hero")?.getBoundingClientRect();
    let imageOffsetFromTopOfWindow = 0;

    if (imageRect) {
      imageOffsetFromTopOfWindow = imageRect.top + window.scrollY;
      setImageOffset(imageOffsetFromTopOfWindow);
    }

    const handleScroll = throttle(() => {
      const el = progressRef.current;
      if (el) {
        const top = el.getBoundingClientRect().top;
        const height = el.offsetHeight;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        const percentComplete = (window.scrollY / contentHeight) * 100;

        setProgress(clamp(+percentComplete.toFixed(2), 0, 105));

        if (top + window.scrollY < imageOffsetFromTopOfWindow) {
          return setShouldFixAside(false);
        }

        if (top + height / 2 <= windowHeight / 2) {
          return setShouldFixAside(true);
        }
      }
    }, 20);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, [contentHeight]);

  return (
    <AsideContainer>
      <Align show={show} imageOffset={imageOffset} shouldFixAside={shouldFixAside}>
        <div ref={progressRef}>
          <HandleOverlap>{childrenWithProps}</HandleOverlap>
        </div>
      </Align>
    </AsideContainer>
  );
}
