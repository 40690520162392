import React, { useCallback, useState, CSSProperties } from "react";
import styled from "styled-components";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import theme from "~/theme";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface ImageZoomProps {
  src: string;
  alt?: string;
  style?: CSSProperties;
  className?: string;
}

const ImageZoom: React.FC<ImageZoomProps> = ({ className, ...props }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const image = {
    ...props,
    className: `${className} Image__Zoom`,
    style: {
      display: "block",
      margin: "0 auto",
      maxWidth: "100%",
      maxHeight: "75vh",
      borderRadius: isZoomed ? "5px" : "0px",
    },
  };

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <Container>
      <ControlledZoom
        isZoomed={isZoomed}
        onZoomChange={handleZoomChange}
        zoomMargin={40}
        overlayBgColorEnd={theme.palette.overlayBackground}>
        <img {...image} />
      </ControlledZoom>
    </Container>
  );
};

export default ImageZoom;
