import React from "react";
import {
  Container,
  Header,
  HeroHeading,
  HeroSubtitle,
  HeroImage,
  ChipsContainer,
  StyledChip,
} from "./style";
import Image from "gatsby-image";
import { IProject } from "~/types/Project";

interface HeroProps {
  project: IProject;
}

export default function Hero({ project }: HeroProps) {
  return (
    <Container>
      <Header>
        <HeroHeading>{project.name}</HeroHeading>
        <HeroSubtitle>
          <strong>{project.type}</strong>
          {project.date && (
            <span>
              {" · "}
              {new Date(project.date).getFullYear()}
            </span>
          )}
          <span>
            {" · "}
            {project.timeToRead} min to read
          </span>
          <ChipsContainer>
            {project.technologies.map(technology => (
              <StyledChip key={technology}>{technology}</StyledChip>
            ))}
          </ChipsContainer>
        </HeroSubtitle>
      </Header>
      <HeroImage id="ArticleImage__Hero">
        {project.heroImgSrc && <Image fluid={project.heroImgSrc.childImageSharp.fluid} />}
      </HeroImage>
    </Container>
  );
}
