import React from "react";
import { Excerpt, ImageContainer, Item, MetaData, ProjectLink, Title } from "./style";
import { IProject } from "~/types/Project";
import Image from "gatsby-image";
import { ArrowCursorLink } from "~/components/Link";

interface GridItemProps {
  project: IProject;
  narrow?: boolean;
}

export default function GridItem({ project, narrow }: GridItemProps) {
  if (!project) return null;

  const hasOverflow = narrow && project.name.length > 35;

  return (
    <ArrowCursorLink>
      <ProjectLink to={`/${project.slug}`} data-a11y="false" narrow={narrow ? "true" : "false"}>
        <Item>
          <ImageContainer>
            {/* use hero not bgImgSrc here */}
            <Image fluid={project.bgImgSrc.childImageSharp.fluid} />
          </ImageContainer>
          <Title hasOverflow={hasOverflow}>{project.name}</Title>
          {/* <Excerpt hasOverflow={!!hasOverflow}>{project.excerpt}</Excerpt> */}
          <Excerpt hasOverflow={!!hasOverflow}>Excerpt Placeholder</Excerpt>
          <MetaData>
            {`${project.date && new Date(project.date).getFullYear()} · ${
              project.timeToRead
            } min read`}
          </MetaData>
        </Item>
      </ProjectLink>
    </ArrowCursorLink>
  );
}
