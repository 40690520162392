import React from 'react';
import GridItem from "./GridItem";
import { IProject } from '~/types/Project';
import { Container, FooterNext, Grid, FooterSpacer } from "./style";

interface NextProjectProps {
  projects: IProject[];
}

export default function NextProject({ projects }: NextProjectProps) {
  if (!projects) return null;
  const numberOfProjects = projects.length;
  return (
    <Container narrow>
      <FooterNext>More projects</FooterNext>
      <Grid numberOfProjects={numberOfProjects}>
        <GridItem project={projects[0]} />
        <GridItem project={projects[1]} narrow />
      </Grid>
      <FooterSpacer />
    </Container>
  );
}
