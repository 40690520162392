// taken/ adapted from https://github.com/narative/gatsby-theme-novela/blob/master/%40narative/gatsby-theme-novela
import styled, { css } from "styled-components";

export const Figcaption = styled.figcaption`
  color: ${p => p.theme.palette.onBackground.shade1};
  font-size: 14px;
  text-align: center;
  width: 100%;
  padding-top: 6px;
`;

const IMAGE_WIDTHS = {
  regular: "680px",
  large: "1004px",
  full: "100vw",
};

const ARTICLE_WIDTH = css`
  ${({ theme }) => css`
    width: 100%;
    max-width: 680px;
    ${theme.media.desktop`
    max-width: 507px;
  `}
    ${theme.media.tablet`
    max-width: 486px;
  `};
    ${theme.media.phablet`
    padding: 0 20px;
  `};
  `}
`;

export const Container = styled.article`
  ${({ theme }) => css`
  position: relative;
  padding: 160px 0 35px;
  padding-left: 68px;
  transition: background 0.2s linear;
  ${theme.media.desktop`
    padding-left: 53px;
  `}
  ${theme.media.tablet`
    padding: 70px 0 80px;
  `}
  ${theme.media.phablet`
    padding: 60px 0;
  `}

  * {
    box-sizing: border-box;
  }
`}
`;

const HeadingsCSS = css`
  ${({ theme }) => css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 auto;
    }
    h1,
    h1 > *,
    h2,
    h2 > * {
      margin: 25px auto 18px;
      ${theme.media.tablet`
      margin: 30px auto 18px;
    `};
    }
    h3,
    h3 > * {
      margin: 25px auto 18px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      ${ARTICLE_WIDTH};
    }
    h5,
    h6 {
      margin: 25px auto 18px;
    }
  `}
`;

const ImageCSS = css`
  ${({ theme }) => css`
    .gatsby-resp-image-background-image {
      display: none !important;
    }
    img {
      display: inline-block;
      position: relative;
      max-width: 100%;
      height: auto;
      z-index: 0;
      margin: 15px auto 50px;
      border-radius: 5px;
      ${theme.media.tablet`
      margin: 10px auto 45px;
    `};
    }
    div.Image__Small {
      display: flex;
      flex-direction: column;
      position: relative;
      max-width: 100%;
      height: auto;
      z-index: 0;
      margin: 15px auto 50px;
      border-radius: 5px;
      width: 100%;
      max-width: 680px;
      ${theme.media.tablet`
      margin: 10px auto 45px;
    `};
      ${theme.media.desktop`
      max-width: 507px;
    `}
      ${theme.media.tablet`
      max-width: 486px;
      margin: 0 auto 25px;
    `};
      ${theme.media.phablet`
      padding: 0 20px;
    `};
    }
    .Image__Container {
      text-align: center;
    }
    img.Image__With-Shadow {
      box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.15);
    }
    div.Image__Medium {
      position: relative;
      margin: 15px auto 50px;
      width: 100%;
      max-width: ${IMAGE_WIDTHS.large};
      ${theme.media.desktop_medium`
      left: -34px;
    `};
      ${theme.media.desktop`
      left: -26px;
    `};
      ${theme.media.tablet`
      border-radius: 0;
      left: 0;
      margin: 0 auto 25px;
      img {
        border-radius: 0;
      }
    `};
    }
    div.Image__Large {
      position: relative;
      left: -68px;
      width: ${IMAGE_WIDTHS.full};
      margin: 25px auto 60px;
      pointer-events: none;
      /* To allow interaction for all external interactions: YouTube, Twitter, Gist */
      iframe {
        pointer-events: all;
      }
      img {
        border-radius: 0;
      }
      ${theme.media.desktop`
      left: -53px;
    `};
      ${theme.media.tablet`
      left: 0;
      margin: 0 auto 25px;
    `};
    }
  `}
`;

/**
 * MDXBody
 * Here we're applying "global" selectors to make sure we maintain an article
 * body type feel. We're also applying all the Prism selecotors and styles within
 * the MDXBody.
 */
export const MDXBody = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${HeadingsCSS}
  ${ImageCSS}
`;
