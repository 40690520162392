import styled, { css } from "styled-components";
import { Link } from "gatsby";

export const Container = styled.section<{ narrow?: boolean }>`
  display: block;
  width: 100%;
  ${p => p.theme.dimensions.mixins.centeredBody.static}
  ${p => p.theme.media.desktop`
    max-width: 850px;
  `};
  ${p =>
    p.narrow
      ? p => p.theme.media.tablet`
          padding: 0 2rem;
          box-sizing: border-box;
          max-width: 527px;
        `
      : p => p.theme.media.tablet`
          padding: 0 4rem;
          box-sizing: border-box;
          max-width: 567px;
        `}
  ${p => p.theme.media.phablet`
    max-width: 100%;
  `};
`;

export const FooterNext = styled.h5`
  position: relative;
  opacity: 0.25;
  margin-bottom: 100px;
  font-weight: 400;
  color: ${p => p.theme.palette.onBackground.main};
  ${p => p.theme.media.tablet`
    margin-bottom: 60px;
  `}
  &::after {
    content: '';
    position: absolute;
    background: ${p => p.theme.palette.onBackground.shade1};
    width: ${(910 / 1140) * 100}%;
    height: 1px;
    right: 0;
    top: 11px;
    ${p => p.theme.media.tablet`
      width: ${(600 / 1140) * 100}%;
    `}
    ${p => p.theme.media.phablet`
      width: ${(400 / 1140) * 100}%;
    `}
    ${p => p.theme.media.phone`
      width: 90px
    `}
  }
`;

export const FooterSpacer = styled.div`
  margin-bottom: 65px;
`;

const wide = "1fr";
const narrow = "457px";

const limitToTwoLines = css`
  text-overflow: ellipsis;
  overflow-wrap: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;
  ${p => p.theme.media.phablet`
    -webkit-line-clamp: 3;
  `}
`;
export const Grid = styled.div<{ numberOfProjects: number }>`
  position: relative;
  display: grid;
  ${p => {
    if (p.numberOfProjects === 1) {
      return `
      grid-template-columns: 1fr;
      grid-template-rows: 1
    `;
    } else {
      return `
      grid-template-columns: ${wide} ${narrow};
      grid-template-rows: 2;
      `;
    }
  }}
  column-gap: 30px;
  margin: 0 auto;
  max-width: ${p => (p.numberOfProjects === 1 ? "680px" : "100%")};
  ${p => p.theme.media.desktop`
    grid-template-columns: 1fr 1fr;
  `}
  ${p => p.theme.media.tablet`
    grid-template-columns: 1fr;
  `}
`;

export const ImageContainer = styled.div<{ narrow?: boolean }>`
  position: relative;
  height: 280px;
  margin-bottom: 30px;
  transition: transform 0.2s ${p => p.theme.easings.easeInOut};
  & > div {
    height: 100%;
  }
  ${p => p.theme.media.tablet`
    height: 220px;
    margin-bottom: 35px;
  `}
  ${p => p.theme.media.phablet`
    height: 200px;
    margin-bottom: 0;
    box-shadow: none;
    overflow: hidden;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  `}
`;

export const Item = styled.div`
  position: relative;
  @media (max-width: 540px) {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background: ${p => p.theme.palette.card.glassy};
  }
`;

export const Title = styled.h3<{ hasOverflow?: boolean }>`
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: ${p => (p.hasOverflow ? "45px" : "10px")};
  color: ${p => p.theme.palette.onBackground.main};
  transition: color 0.3s ease-in-out;
  padding: 0 2rem;
  ${limitToTwoLines};
  ${p => p.theme.media.tablet`
    margin-bottom: 15px;
  `}
  ${p => p.theme.media.phablet`
    padding: 30px 20px 0;
    margin-bottom: 10px;
    -webkit-line-clamp: 3;
  `}
`;

export const Excerpt = styled.p<{ narrow?: boolean; hasOverflow: boolean }>`
  ${limitToTwoLines};
  font-size: 16px;
  margin-bottom: 10px;
  padding: 0 2rem;
  box-sizing: border-box;
  color: ${p => p.theme.palette.onBackground.shade1};
  display: ${p => (p.hasOverflow ? "none" : "box")};
  max-width: ${p => (p.narrow ? "415px" : "515px")};
  ${p => p.theme.media.desktop`
    display: -webkit-box;
  `}
  ${p => p.theme.media.tablet`
    margin-bottom: 15px;
  `}
  ${p => p.theme.media.phablet`
    max-width: 100%;
    padding:  0 20px;
    margin-bottom: 20px;
    -webkit-line-clamp: 3;
  `}
`;

export const MetaData = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${p => p.theme.palette.onBackground.shade1};
  opacity: 0.33;
  padding: 0 2rem 2rem 2rem;

  ${p => p.theme.media.phablet`
    max-width: 100%;
    padding:  0 20px 30px;
  `}
`;

export const ProjectLink = styled(Link)<{ narrow: string }>`
  text-decoration: none;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: transform 0.2s ${p => p.theme.easings.easeInOut},
    box-shadow 0.2s ${p => p.theme.easings.easeInOut};
  border-radius: 0.5rem;
  border: 2px solid ${p => p.theme.palette.card.glassy};
  background: ${p => p.theme.palette.card.glassy};
  overflow: hidden;
  box-shadow: ${p => (p.narrow ? p.theme.shadows.lg : p.theme.shadows.xl)};
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:hover {
    box-shadow: ${p => p.theme.shadows.xxl};
    transform: scale(1.03);
  }

  &:hover ${ImageContainer} {
    transform: scale(1.01);
  }
  &:hover h2,
  &:focus h2 {
    color: ${p => p.theme.palette.accent};
  }
  &[data-a11y="true"]:focus::after {
    content: "";
    position: absolute;
    left: -2%;
    top: -2%;
    width: 104%;
    height: 104%;
    border: 3px solid ${p => p.theme.palette.accent};
    background: rgba(255, 255, 255, 0.01);
  }
  ${p => p.narrow === "true" && p.theme.media.tablet`display: none;`}
  ${p => p.theme.media.phablet`
    &:hover ${ImageContainer} {
      transform: none;
      box-shadow: initial;
    }
    &:active {
      transform: scale(0.97) translateY(3px);
    }
  `}
`;
