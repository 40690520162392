import React, { useState, useRef, useEffect } from "react";
import Template from "~/types/Template";
import Hero from "~/sections/Project/Hero";
import Body from "~/sections/Project/Body";
import ProjectSEO from "~/sections/Project/ProjectSEO";
import NextProject from "~/sections/Project/NextProject";
import Footer from "~/components/Footer";
import throttle from "lodash.throttle";
import debounce from "lodash.debounce";
import Aside from "~/sections/Project/Aside";
import ScrollProgressIndicator from "~/components/ScrollProgressIndicator";

const ProjectTemplate: Template = ({ pageContext, location }) => {
  const { project, next } = pageContext;
  const contentSectionRef = useRef<HTMLElement>(null);
  const [hasCalculated, setHasCalculated] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);

  useEffect(() => {
    const calculateBodySize = throttle(() => {
      const contentSection = contentSectionRef.current;

      if (!contentSection) return;

      /**
       * If we haven't checked the content's height before,
       * we want to add listeners to the content area's
       * imagery to recheck when it's loaded
       */
      if (!hasCalculated) {
        const debouncedCalculation = debounce(calculateBodySize);
        const $imgs = contentSection.querySelectorAll("img");

        $imgs.forEach($img => {
          // If the image hasn't finished loading then add a listener
          if (!$img.complete) $img.onload = debouncedCalculation;
        });

        // Prevent rerun of the listener attachment
        setHasCalculated(true);
      }

      // Set the height and offset of the content area
      setContentHeight(contentSection.getBoundingClientRect().height);
    }, 20);

    calculateBodySize();
    window.addEventListener("resize", calculateBodySize);

    return () => window.removeEventListener("resize", calculateBodySize);
  }, []);
  return (
    <div>
      <ProjectSEO project={project} location={location} />
      <Hero project={project} />
      <Aside contentHeight={contentHeight}>
        <ScrollProgressIndicator contentHeight={contentHeight} />
      </Aside>
      <Body content={project.body} ref={contentSectionRef} />
      {next.length > 0 && <NextProject projects={next} />}
      <Footer />
    </div>
  );
};

export default ProjectTemplate;
