import React, { useCallback, useState, ReactNode } from "react";
import styled, { css } from "styled-components";
import { InView } from "react-intersection-observer";
import { motion, Transition, useAnimation, useViewportScroll } from "framer-motion";

const Paragraph = styled.p`
  ${({ theme }) => css`
    margin: 0 auto 35px;
    width: 100%;
    color: ${theme.palette.onBackground.shade1};
    ${theme.media.tablet`
      margin: 0 auto 25px;
    `};
    ${theme.media.phablet`
      padding: 0 20px;
    `};
  `}
`;

const MotionContainer = styled(motion.span).attrs({
  initial: "initial",
  variants: { initial: {}, animate: {} },
})``;

const defaultTransition: Transition = {
  ease: "easeOut",
  duration: 0.75,
  delay: 0.15,
};

interface AppearingParagraphProps {
  children: ReactNode;
  triggerOffset?: number;
  transition?: Transition;
}

export default function AppearingParagraph({
  children,
  triggerOffset = 100,
  transition = defaultTransition,
  ...props
}: AppearingParagraphProps) {
  const [isInView, setIsInView] = useState(false);
  const controls = useAnimation();
  const { scrollY } = useViewportScroll();
  const handleInViewChange = useCallback(
    (newInView, entry) => {
      if (newInView && entry && !isInView) {
        controls.start("animate");
        setIsInView(true);
      } else {
        const topPos = entry?.boundingClientRect?.top;
        const pageYOffset = entry?.target?.ownerDocument?.defaultView?.pageYOffset;
        if (topPos && pageYOffset) {
          // only hide when the content is below the viewport.
          // If the user has scrolled past it, let it stay visible
          const contentTopInViewport = topPos + pageYOffset - triggerOffset;
          const contentIsAboveView = contentTopInViewport < scrollY.get();
          if (!contentIsAboveView) {
            controls.start("initial");
            setIsInView(false);
          }
        }
      }
    },
    [controls, scrollY],
  );

  return (
    <Paragraph {...props}>
      <MotionContainer animate={controls}>
        <InView
          as="span"
          onChange={handleInViewChange}
          rootMargin={!isInView ? `-${triggerOffset}px 0px` : "0px"}>
          <motion.span
            variants={{
              initial: {
                opacity: 0,
              },
              animate: {
                opacity: 1,
              },
            }}
            transition={transition}>
            {children}
          </motion.span>
        </InView>
      </MotionContainer>
    </Paragraph>
  );
}
